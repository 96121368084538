class LogUtils {
    /**
     * @deprecated
     * @type {string}
     */
    static blankDescription = this.#noDescriptionString("Log");
    /**
     * @deprecated
     * @type {string}
     */
    static chemicalApplicationDescription = this.#noDescriptionString("Chemical Application");
    /**
     * @deprecated
     * @type {string}
     */
    static coolerInspectionDescription = this.#noDescriptionString("Cooler Inspection");
    /**
     * @deprecated
     * @type {string}
     */
    static correctiveActionsDescription = this.#noDescriptionString("Corrective Actions");
    /**
     * @deprecated
     * @type {string}
     */
    static inputsReceivingDescription = "Use the Inputs Receiving Log to record receipt of shipments containing any of the following: growing pads, oasis cubes, perlite, vermiculite, nutrient fertilizers, agar plates, Unicorn bags, plant sprays or drenches, food contact cleaners, clamshells, tills, pots, bulk bag packaging, and labels.";
    /**
     * @deprecated
     * @type {string}
     */
    static maintenanceDescription = this.#noDescriptionString("Maintenance");
    /**
     * @deprecated
     * @type {string}
     */
    static negProductReceivingDescription = this.#noDescriptionString("NEG Product Receiving");
    /**
     * @deprecated
     * @type {string}
     */
    static nonGroceryCustomerOrderDescription = this.#noDescriptionString("Non-Grocery Customer Order");
    /**
     * @deprecated
     * @type {string}
     */
    static soilAmendmentDescription = "Record all new batch mixes of vermiculite/perlite and new batches of hydroponic nutrients.";
    /**
     * @deprecated
     * @type {string}
     */
    static wildlifeAssessmentDescription = "Use the Wildlife Assessment Log to record inspection of the following areas for mice or rodent activity. Inspect all traps, walls, doors, and windows for any droppings, chewing, or holes/gaps that could let a rodent inside the farm.";
    /**
     * @deprecated
     * @type {string}
     */
    static makeOysterBagsDescription = this.#noDescriptionString("Make Oyster Bags");
    /**
     * @deprecated
     * @type {string}
     */
    static makeShiitakeBagsDescription = this.#noDescriptionString("Make Shiitake Bags");
    /**
     * @deprecated
     * @type {string}
     */
    static mushroomForecastDescription = this.#noDescriptionString("Mushroom Forecast");
    /**
     * @deprecated
     * @type {string}
     */
    static makeSpawnBagsDescription = this.#noDescriptionString("Make Spawn Bags");
    /**
     * @deprecated
     * @type {string}
     */
    static inoculateSpawnBagsDescription = this.#noDescriptionString("Inoculate Spawn Bags");
    /**
     * @deprecated
     * @type {string}
     */
    static inoculateOysterBagsDescription = this.#noDescriptionString("Inoculate Oyster Bags");
    /**
     * @deprecated
     * @type {string}
     */
    static inoculateShiitakeBagsDescription = this.#noDescriptionString("Inoculate Shiitake Bags");
    /**
     * @deprecated
     * @type {string}
     */
    static preHarvestDescription = this.#noDescriptionString("Pre-Harvest");
    /**
     * @deprecated
     * @type {string}
     */
    static harvestOystersDescription = this.#noDescriptionString("Harvest Oysters");
    /**
     * @deprecated
     * @type {string}
     */
    static harvestShiitakeDescription = this.#noDescriptionString("Harvest Shiitakes");
    /**
     * @deprecated
     * @type {string}
     */
    static postPackagingDescription = this.#noDescriptionString("Post-Packaging");


    /**
     * @deprecated
     * @type {string}
     */
    static #noDescriptionString(log) {
        return "The " +log + " Log does not yet have a description."
    }
}

try {
    module.exports = LogUtils
} catch (ReferenceError) {

}