class PageUtils {
    /**
     * Function to toggle the popup
     *
     * @param popup Name of the popup
     */
    static togglePopup(popup) {
        // Get the outer content div(s) and blur them
        const contentOuter = document.querySelectorAll('.content-outer-container');
        contentOuter.forEach(outer => {
            outer.classList.toggle('popup--active');
        })

        // Get the popup(s) and show it
        const popupOuter = document.querySelectorAll('.popup-outer-' + popup);
        popupOuter.forEach(outer => {
            outer.classList.toggle('popup--active');
        })

        const sidebar = sessionStorage.getItem("sidebar")
        if (sidebar === "0") {
            $('.popup-outer.popup--active').css({marginLeft: "0"})

        } else if (sidebar === "1") {
            $('.popup-outer.popup--active').css({marginLeft: "var(--sidebar-width)"})

        } else {
            $('.popup-outer.popup--active').css({marginLeft: "var(--sidebar-width)"})

        }
    }

    /**
     * Function to get all open popups
     *
     * @returns {NodeListOf<Element>}
     */
    static getAllOpenPopups() {
        return document.querySelectorAll('.popup-outer.popup--active');
    }

    /**
     * Function to close all open popups
     */
    static closeAllOpenPopups() {
        // Get all open popups
        const openPopups = PageUtils.getAllOpenPopups();

        // Iterate over each open popup and toggle the 'popup--active' class to close them
        openPopups.forEach(popup => {
            popup.classList.remove('popup--active');

            // Optionally reset any inline styles if necessary
            popup.style.marginLeft = "";
        });

        // Optionally, clear any blur or overlay effects on other content
        const contentOuter = document.querySelectorAll('.content-outer-container');
        contentOuter.forEach(outer => {
            outer.classList.remove('popup--active');
        });
    }

    /**
     * Function to refresh objects in a page
     *
     * @param obj Parent object
     * @param search Search for the object
     * @param html HTML to insert/replace
     * @param s Sort function to use
     */
    static refreshObject(obj, search, html, s) {
        // Check if the search found anything
        if (search.length === 0) {
            // Add the HTML to the object div
            obj.append(html)

            // Sort the div
            obj.children().sort((a, b) => s(a, b)).appendTo(obj)

        } else {
            // Add the HTML to the search div
            const search_div = $(search[0])
            search_div.replaceWith(html)
        }
    }

    /**
     * Function to parse a JSON string into JSON
     *
     * @param str The JSON string to parse
     */
    static parseJSONString(str) {
        return JSON.parse(str.replaceAll("&quot;", '"'))
    }

    /**
     * Function to dispatch a window resize event
     */
    static dispatchWindowResizeEvent() {
        window.dispatchEvent(new Event('resize'));
    }

    /**
     * Function to get the total available height for content on the page
     *
     * @returns {number} The usable height
     */
    static getUsableContentHeight() {
        // header: 105
        // top of containers: 24
        // bottom: 30
        return window.innerHeight - 105 - 24 - 30;
    }
}

try {
    module.exports = PageUtils
} catch (ReferenceError) {

}

// export default PageUtils