import PageUtils from "./PageUtils";

/**
 * Class to generate and control date inputs that the user can select more than one date
 */
class MultipleDateSelect {

    /**
     * Map to store the control classes
     *
     * @type {Map<any, any>}
     */

    static #controlMap = new Map();
    /**
     * Get the control class from the given container
     *
     * @type {Map<any, any>}
     */
    static getControl(container) {
        return this.#controlMap.get(container)
    }

    /**
     * Variable to store the currently open calendar
     */
    static #openCalendar = undefined;

    // Settings
    static #highlightToday = true;
    static #highlightedClass = "highlight"
    static #dateSeparator = ', ';

    /**
     * Function to calculate the number of days in a given month
     *
     * @param month Month
     * @param year Year
     */
    static #daysInMonth(month, year) {
        return 32 - new Date(year, month, 32).getDate();
    }

    /**
     * Function to turn a list of date strings into a string
     *
     * @param dates List of date strings
     */
    static #datesToString(dates) {
        return dates.join(this.#dateSeparator);
    }

    /**
     * Function to format a date into a date string
     *
     * @param date Date to format
     */
    static #formatDate(date) {
        return (date.getMonth() + 1).toString() + '/' + date.getDate().toString() + '/' + date.getFullYear()
    }

    /**
     * Function to hide the calendar for the given container
     *
     * @param container Main container of the calendar
     */
    static #hideCalendarByContainer(container) {
        container.find('input').css({borderRadius: "5px"})

        const calendar = container.find('#calendar-parent');
        calendar.hide();
    }



    /**
     * Constructor
     *
     * @param container Container to inject the MultipleDateSelect into
     */
    constructor(container) {
        this.container = container
        MultipleDateSelect.#controlMap.set(container[0], this)

        this.today = new Date();
        this.currentMonth = this.today.getMonth();
        this.currentYear = this.today.getFullYear();

        this.#inject()

        this.months = [];
        this.selectedDates = [];
        this.years = [];

        this.minYear = 2013
        this.maxYear = this.currentYear + 1

        const selectYear = this.container.find("year");
        for (let year = this.minYear; year <= this.maxYear; year++) {
            selectYear.append($('<option>', {
                value: year,
                text: year,
            }));
        }

        this.#loadControl()


        this.container.find('#previous').on('click', () => {
            this.#previous()
        })

        this.container.find('#month').on('change', () => {
            this.#change()
        })

        this.container.find('#year').on('change', () => {
            this.#change()
        })

        this.container.find("#next").on('click', () => {
            this.#next()
        })
    }


    /**
     * Function to toggle the dates in the list
     *
     * @param dates List of dates to toggle
     */
    toggleSelectedDates(dates) {
        for (const date of dates) {
            this.toggleSelectedDate(date)
        }
    }

    /**
     * Function to toggle the date
     *
     * @param date Date to toggle
     */
    toggleSelectedDate(date) {
        this.#addValueToInput(date, undefined)
    }


    /**
     * Function to inject the HTML into the {@link this.container}
     */
    #inject() {
        const label = this.container.find("label")
        const input = this.container.find("input")
        const required = input.attr("required")

        let html = '<div>'
        if (label.length > 0) {
            html += '<label for="' + input.attr('id') + '">' + label.html() + '</label>'
        }

        html += '<input type="text" id="' + input.attr('id') + '" name="' + input.attr('name') + '" class="date-values" readonly/>' +
            '<div id="calendar-parent" style="display:none;">' +
            '<div class="header-row">' +
            '<div class="previous">' +
            '<div id="previous">' +
            '<i class="fa fa-arrow-left" aria-hidden="true"></i>' +
            '</div>' +
            '</div>' +
            '<div class="card-header month-selected" id="monthAndYear"></div>' +
            '<div>' +
            '<select name="month" id="month">' +
            '<option value="0">Jan</option>' +
            '<option value="1">Feb</option>' +
            '<option value="2">Mar</option>' +
            '<option value="3">Apr</option>' +
            '<option value="4">May</option>' +
            '<option value="5">Jun</option>' +
            '<option value="6">Jul</option>' +
            '<option value="7">Aug</option>' +
            '<option value="8">Sep</option>' +
            '<option value="9">Oct</option>' +
            '<option value="10">Nov</option>' +
            '<option value="11">Dec</option>' +
            '</select>' +
            '</div>' +
            '<div class="col-sm">' +
            '<select class="form-control col-xs-6" name="year" id="year" onchange="change()"></select>' +
            '</div>' +
            '<div class="col-xs next">' +
            '<div id="next">' +
            '<i class="fa fa-arrow-right" aria-hidden="true"></i>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '<table id="calendar">' +
            '<thead>' +
            '<tr>' +
            '<th>S</th>' +
            '<th>M</th>' +
            '<th>T</th>' +
            '<th>W</th>' +
            '<th>T</th>' +
            '<th>F</th>' +
            '<th>S</th>' +
            '</tr>' +
            '</thead>' +
            '<tbody id="calendarBody"></tbody>' +
            '</table>' +
            '</div>' +
            '</div>'


        this.container.html(html)

        this.container.find("input").attr("required", required)
    }


    /**
     * Function to load the next month
     */
    #next() {
        this.currentYear = this.currentMonth === 11 ? this.currentYear + 1 : this.currentYear;
        this.currentMonth = this.currentMonth + 1 % 12;
        this.#updateSelects();
        this.#loadControl();
    }

    /**
     * Function to load the previous month
     */
    #previous() {
        this.currentYear = this.currentMonth === 0 ? this.currentYear - 1 : this.currentYear;
        this.currentMonth = this.currentMonth === 0 ? 11 : this.currentMonth - 1;
        this.#updateSelects();
        this.#loadControl();
    }

    /**
     * Function to update the month and year selects
     */
    #updateSelects() {
        this.container.find("#month").val(this.currentMonth);
        this.container.find("#year").val(this.currentYear);
    }

    /**
     * Function to reset the MultipleDateSelect
     */
    #resetCalendar() {
        this.selectedDates = [];
        this.container.find('#calendarBody tr').each(function () {
            $(this).find('td').each(function () {
                $(this).removeClass(MultipleDateSelect.#highlightedClass);
            });
        });
    };

    /**
     * Function called when the month or year is changed
     */
    #change() {
        this.currentYear = parseInt(this.container.find("#year").val());
        this.currentMonth = parseInt(this.container.find("#month").val());
        this.#updateSelects();
        this.#loadControl();
    }


    /**
     * Function to load a new year if needed
     *
     * @param selectedYear The selected year
     */
    #addYears(selectedYear) {
        if (this.years.length > 0) {
            return;
        }

        for (let year = this.minYear; year <= this.maxYear; year++) {
            this.years.push(year);
            this.container.find("#year").append($('<option>', {
                value: year,
                text: year,
                defaultSelected: parseInt(year) === parseInt(selectedYear),
            }));
        }
    }

    /**
     * Function to add the buttons and calendar to the MultipleDateSelect
     *
     * @param table Table to inject the HTML into
     */
    #addButtonPanel(table) {
        // after we have looped for all the days and the calendar is complete,
        // we will add a panel that will show the buttons, reset and done
        const row = document.createElement("tr");
        row.className = 'buttonPanel';

        const cell = document.createElement("td");
        cell.colSpan = 7;

        const parentDiv = document.createElement("div");
        parentDiv.classList.add('row');
        parentDiv.classList.add('buttonPanel-row');


        const div = document.createElement("div");
        div.className = 'col-sm';

        const resetButton = document.createElement("button");
        resetButton.className = 'btn';
        resetButton.value = 'Reset';
        resetButton.type = 'button';
        resetButton.onclick = () => this.#resetCalendar();

        const resetButtonText = document.createTextNode("Reset");
        resetButton.appendChild(resetButtonText);

        div.appendChild(resetButton);
        parentDiv.appendChild(div);


        const div2 = document.createElement("div");
        div2.className = 'col-sm';
        const doneButton = document.createElement("button");
        doneButton.className = 'btn';
        doneButton.value = 'Done';
        doneButton.type = 'button';
        doneButton.onclick = () => this.#hideCalendar();
        const doneButtonText = document.createTextNode("Done");
        doneButton.appendChild(doneButtonText);

        div2.appendChild(doneButton);
        parentDiv.appendChild(div2);

        cell.appendChild(parentDiv);
        row.appendChild(cell);
        table.appendChild(row);
    }


    /**
     * Function to add a value to the MultipleDateSelect
     *
     * @param date Date to add (if known)
     * @param element Element of the calendar to add (if known)
     */
    #addValueToInput(date=undefined, element=undefined) {
        if (!element && !date) {
            console.error("Either a date or an element is required")
            return
        }

        if (!element) {
            element = this.container.find('#' + MultipleDateSelect.#formatDate(date).replaceAll("/", "\\/"))
        }

        const id = element.attr('id');
        if (typeof id !== typeof undefined) {
            const classes = element.attr('class');
            if (typeof classes === typeof undefined || !classes.includes(MultipleDateSelect.#highlightedClass)) {
                const selectedDate = new Date(id);
                this.selectedDates.push(MultipleDateSelect.#formatDate(selectedDate));
            } else {
                const index = this.selectedDates.indexOf(id);
                if (index > -1) {
                    this.selectedDates.splice(index, 1);
                }
            }

            element.toggleClass(MultipleDateSelect.#highlightedClass);
        }

        const sortedArray = this.selectedDates.sort((a, b) => new Date(a) - new Date(b));
        this.container.find('.date-values').val(MultipleDateSelect.#datesToString(sortedArray))
        this.container.find('.date-values').trigger('input')
    }

    /**
     * Function to load the listeners and update the HTML
     */
    #loadControl() {
        this.#addYears(this.currentYear);

        let firstDay = (new Date(this.currentYear, this.currentMonth)).getDay();

        // body of the calendar
        const table = this.container.find("#calendarBody")[0];
        // clearing all previous cells
        table.innerHTML = "";

        const monthAndYear = this.container.find("monthAndYear");
        // filing data about month and in the page via DOM.
        monthAndYear.innerHTML = this.months[this.currentMonth] + " " + this.currentYear;


        this.container.find("#year").val(this.currentYear);
        this.container.find("#month").val(this.currentMonth);

        // creating the date cells here
        let date = 1;

        // there will be maximum 6 rows for any month
        for (let rowIterator = 0; rowIterator < 6; rowIterator++) {

            // creates a new table row and adds it to the table body
            let row = document.createElement("tr");

            //creating individual cells, filing them up with data.
            for (let cellIterated = 0; cellIterated < 7 && date <= MultipleDateSelect.#daysInMonth(this.currentMonth, this.currentYear); cellIterated++) {

                // create a table data cell
                const cell = document.createElement("td");
                let textNode = "";

                // check if this is the valid date for the month
                if (rowIterator !== 0 || cellIterated >= firstDay) {
                    cell.id = (this.currentMonth + 1).toString() + '/' + date.toString() + '/' + this.currentYear.toString();
                    cell.class = "clickable";
                    textNode = date;

                    // this means that highlightToday is set to true and the date being iterated it todays date,
                    // in such a scenario we will give it a background color
                    if (MultipleDateSelect.#highlightToday && date === this.today.getDate() && this.currentYear === this.today.getFullYear() && this.currentMonth === this.today.getMonth()) {
                        cell.classList.add("today-color");
                    }

                    // set the previous dates to be selected
                    // if the selectedDates array has the dates, it means they were selected earlier.
                    // add the background to it.
                    if (this.selectedDates.indexOf((this.currentMonth + 1).toString() + '/' + date.toString() + '/' + this.currentYear.toString()) >= 0) {
                        cell.classList.add(MultipleDateSelect.#highlightedClass);
                    }

                    date++;
                }

                const cellText = document.createTextNode(textNode);
                cell.appendChild(cellText);
                row.appendChild(cell);
            }

            table.appendChild(row); // appending each row into calendar body.
        }

        // this adds the button panel at the bottom of the calendar
        this.#addButtonPanel(table);

        // function when the date cells are clicked
        this.container.find("#calendarBody tr td").on('click', event => {
            const clickedElement = $(event.target);
            this.#addValueToInput(undefined, clickedElement)
        });

        const search = this.container.find('.date-values');
        search.on('focus', () => {
            this.#showCalendar();
        });
    }


    /**
     * Function to show the calendar
     */
    #showCalendar() {
        if (MultipleDateSelect.#openCalendar) {
            MultipleDateSelect.#hideCalendarByContainer(MultipleDateSelect.#openCalendar);
        }

        this.container.find('input').css({borderRadius: "5px 5px 0 0"})

        const calendar = this.container.find('#calendar-parent');
        MultipleDateSelect.#openCalendar = calendar.parent();
        calendar.show();
    }

    /**
     * Function to hide the calendar
     */
    #hideCalendar() {
        MultipleDateSelect.#hideCalendarByContainer(this.container)
    }
}

try {
    module.exports = MultipleDateSelect
} catch (ReferenceError) {

}

// export default MultipleDateSelect