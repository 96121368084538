class Utils {
    /**
     * Stores the logs that are displayed on the tasks screen instead of a log page
     * @deprecated
     */
    static getInScreenLogs = [
        "none",
        // "mushroom-forecast",
        // "make-spawn-bags",
        // "inoculate-spawn-bags",
        // "make-oyster-bags",
        // "inoculate-oyster-bags",
        // "make-shiitake-bags",
        // "inoculate-shiitake-bags",
        // "harvest-shiitake",
        // "harvest-oyster",
    ]

    /**
     * Stores the logs that need to have the pre-harvest checklist completed before starting
     * @deprecated
     */
    static getPreHarvestChecklistLogs = [
        // "harvest-shiitake",
        // "harvest-oyster",
    ]

    /**
     * Stores the colors used for different departments
     */
    static departmentColorMap = {
        hydroponic: "#e0f8d8",
        mushroom: "#cae8f8",
        production: "#ffeacb",
        cleaning: "#fed4e0",
        maintenance: "#fef5cc",
        delivery: "#DCCCC2", //"#f4e2d6",
        custom: "#f5e3f9",
        completed: "#cdcdcd",
    }

    /**
     * Stores the text colors used for different departments
     */
    static departmentTextColorMap = {
        hydroponic: "#4a812f",
        mushroom: "#00699e",
        production: "#a05e00",
        cleaning: "#b10034",
        maintenance: "#af6b00",
        delivery: "#7A3C17", //"#9a4c1e",
        custom: "#67007e",
        completed: "#3a3a3a",
    }

    /**
     * Function to capitalize a word
     *
     * @param word Word to capitalize
     */
    static capitalize(word) {
        try {
            return word[0].toUpperCase() + word.slice(1)
        } catch (e) {
            return word
        }
    }

    /**
     * Function to capitalize all words in a string
     *
     * @param words The string of words
     */
    static capitalizeAll(words) {
        words = words.split(" ")

        const capitalized = []
        for (const word of words) {
            capitalized.push(Utils.capitalize(word))
        }

        return capitalized.join(" ")
    }

    /**
     * Function to generate a UUID without the crypto package
     *
     * @param sep Separator to use
     */
    static generateUUID(sep="-") {
        return ("xxxxxxxx" + sep + "xxxx" + sep + "xxxx" + sep + "xxxx" + sep + "xxxxxxxxxxxx")
            .replace(/[x]/g, (c) => {
                const r = Math.floor(Math.random() * 16);
                return r.toString(16);
            });
    }

    /**
     * Converts a full name into a format suitable for navigation menus.
     *
     * @param {string} name - The full name to convert.
     * @returns {string} - The converted name.
     */
    static getNameForNav(name) {
        const split = name.split(/ (.*)/s)
        return split[1] + ", " + split[0]
    }

    /**
     * Function used to prevent instant updates from inputs
     *
     * @param func Function to call
     * @param wait Number of milliseconds to wait before calling the function
     * @returns {(function(...[*]): void)|*} The callable function
     */
    static debounce(func, wait) {
        let timeout;
        return function(...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }
}

try {
    module.exports = Utils
} catch (ReferenceError) {

}

// export default Utils