class TimeUtils {
    /**
     * Function to format a time that is usable in a time input
     *
     * @param time Time to format
     */
    static formatTime(time) {
        // Check if the time is only 1 character
        if (time.toString().length === 1) {
            // Add a 0 in front of the time
            return "0" + time

        } else {
            // Return the time
            return time
        }
    }

    /**
     * Function to get the current time in minutes
     */
    static currentTimeInMinutes() {
        const date = new Date()
        const hours = date.getHours()
        let minutes = date.getMinutes()

        minutes += hours * 60

        return minutes
    }

    /**
     * Function to get the current time in seconds
     *
     * @return {number}
     *
     * TODO write test
     */
    static currentTimeInSeconds() {
        const date = new Date()
        const hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()

        minutes += hours * 60
        seconds += minutes * 60

        return seconds
    }

    /**
     * Function to convert a input time into minutes
     *
     * @param time Time to format
     */
    static inputTimeToMinutes(time) {
        const split_start_time = time.split(":").map(t => parseInt(t))
        return (split_start_time[0] *  60) + split_start_time[1]
    }

    /**
     * Function to convert a time in minutes to a time in hours and minutes
     *
     * @param time Time in minutes
     */
    static minutesToInputTime(time) {
        const hours = Math.floor(Math.abs(time) / 60)
        const minutes = time % 60
        return ([0, 1].includes(Math.sign(time)) ? "" : "-") + this.formatTime(hours) + ":" + this.formatTime(Math.abs(minutes))
    }

    /**
     * Function to convert 24-hour format time to 12-hour format time
     *
     * @param time Time in 24-hour format
     */
    static convert24to12(time) {
        const split = time.split(":")
        const raw_hours = parseInt(split[0])
        let hours = parseInt(raw_hours) % 12;
        if (hours === 0) {
            hours = 12;
        }
        return hours + ':' + split[1] + ' ' + (raw_hours < 12 ? 'AM' : 'PM');
    }

    /**
     * Converts a 12-hour format time to a 24-hour format time.
     *
     * @param {string} time - The time in 12-hour format.
     * @returns {string} - The time in 24-hour format.
     */
    static convert12to24HourOnly(time) {
        const split = time.split(":")
        const raw_hours = parseInt(split[0])
        let hours = parseInt(raw_hours) % 12;
        if (hours === 0) {
            hours = 12;
        }
        return hours + ' ' + (raw_hours < 12 ? 'AM' : 'PM');
    }

    /**
     * Function to convert the dat to 12-hour format time
     *
     * @param date Date to format
     */
    static dateTimeTo12(date) {
        return this.convert24to12(date.getHours() + ":" + this.formatTime(date.getMinutes()))
    }

    /**
     * Function to calculate task length in seconds
     *
     * @param start_time Start time of the task
     * @param end_time End time of the task
     * @param pause_times List of pause/resume times of the task
     */
    static calculateLength(start_time, end_time, pause_times) {
        const times = [...pause_times ?? []]
        times.unshift(start_time)
        times.push(end_time)

        // Create a variable to store the length
        let length = 0

        // Calculate the length in seconds
        for (let i = 0; i < times.length; i += 2) {
            length += (new Date(times[i+1]) - new Date(times[i])) / 1000
        }

        // Return the length in seconds
        return length
    }

    /**
     * Function to convert seconds to hours:minutes
     *
     * @param seconds Seconds to convert
     */
    static secondsToHoursMinutes(seconds) {
        const hours = Math.floor(seconds / 3600)
        seconds -= hours * 3600
        const minutes = Math.ceil(seconds / 60)
        return this.formatTime(hours) + ":" + this.formatTime(minutes)
    }

    /**
     * Function to convert seconds to hours:minutes:seconds
     *
     * @param seconds Seconds to convert
     */
    static secondsToHoursMinutesSeconds(seconds) {
        const hours = Math.floor(seconds / 3600)
        seconds -= hours * 3600
        const minutes = Math.floor(seconds / 60)
        seconds -= minutes * 60
        return this.formatTime(hours) + ":" + this.formatTime(minutes) + ":" + this.formatTime(Math.round(seconds))
    }

    /**
     * Function to convert a string DateTime to hours:minutes
     *
     * TODO write test
     *
     * @param date Date to format
     */
    static dateStringToHoursMinutes(date) {
        date = new Date(date)
        const hours = date.getHours()
        const minutes = date.getMinutes()
        return this.formatTime(hours) + ":" + this.formatTime(minutes)
    }
}

try {
    module.exports = TimeUtils;
} catch (ReferenceError) {

}

// export default TimeUtils