class FormUtils {
    /**
     * Function to detect or add a generate the name of the label for the input
     *
     * @param id ID of the input
     * @param name Name to use (if given)
     * @return {string}
     */
    static #detectLabel(id, name) {
        // Create a variable for the label text
        let label = ""

        // Check if the name isn't set
        if (!name) {
            // Split the id by -
            const name = id.split("_")
            // Loop over the split ID
            for (let i in name) {
                // Add the capitalized word to the label object
                label += name[i][0].toUpperCase() + name[i].slice(1) + " "
            }
            // Remove the last element for the label
            return label.slice(0, -1)

        } else {
            // Otherwise the name is the label
            return name

        }
    }

    /**
     * Function to add a checkbox to a page (used for log views)
     *
     * @param div Name of the div to place the checkbox in
     * @param id ID of the element
     * @param name Name of the element
     */
    static addCheckbox(div, id, name=null, checked=false) {
        const label = this.#detectLabel(id, name)

        // Add the checkbox to the div
        $("#" + div).append(
            '<div class="form-container">' +
            '<label for="' + id + '">' + label + '</label>' +
            (checked ? '<input type="checkbox" name="' + id + '" id="' + id + '" checked/>' : '<input type="checkbox" name="' + id + '" id="' + id + '"/>') +
            '</div>'
        )
    }

    /**
     * Function to add a select to a page (used for log views)
     *
     * @param div Name of the div to place the select in
     * @param id ID of the element
     */
    static addYesNo(container, id, name=null, value=null) {
        const label = this.#detectLabel(id, name)

        // Add the select to the div
        container.append(
            '<div class="form-container">' +
            '<label for="' + id + '">' + label + '</label>' +
            '<select id="' + id + '" name="' + id + '" required>' +
            '<option value="true">Yes</option>' +
            '<option value="false">No</option>' +
            '</select>' +
            '</div>'
        )

        // Update the value of the select
        let selectValue = null
        if (value === true || value === 1) {
            selectValue = "true"
        } else if (value === false || value === 0) {
            selectValue = "false"
        }

        container.find('#' + id).val(selectValue);
    }

    /**
     * Function to get all selected options in a select field
     *
     * @param select The select element
     */
    static getSelectedItems(select) {
        return [...select.options]
            .filter(option => option.selected)
            .map(option => option.value)
    }

    /**
     * Function to clear all selected options in a select field
     *
     * @param id ID of the element
     */
    static clearSelect(id, container=$(document)) {
        const select = container.find(`#${id}`)
        if (!select) {
            return
        }

        select.val(null)
    }

    /**
     * Function to select an element in a select field
     *
     * @param id ID of the element
     * @param element Element to select
     */
    static updateSelect(id, element, container=$(document)) {
        this.updateSelectList(id, [element], container)
    }

    /**
     * Function to select elements in a select field
     *
     * @param id ID of the element
     * @param list List of elements to select
     */
    static updateSelectList(id, list, container=$(document)) {
        const select = container.find(`#${id}`)[0]
        if (!select) {
            return
        }

        for (let i in select.options) {
            if (list.includes(select.options[i].value)) {
                select.options[i].selected = true;
            }
        }
    }

    /**
     * Function to set the value of a text area
     *
     * @param id ID of the element
     * @param lines Lines to add to the text area
     */
    static updateTextArea(id, lines, container=$(document)) {
        container.find(`#${id}`).val(lines.replaceAll(",", "\n"))
        // const area = document.getElementById(id)
        // area.value = lines.replaceAll(",", "\n")
    }

    static updateTextAreaFromList(id, list, container=$(document)) {
        if (typeof list === "object") {
            container.find(`#${id}`).val(list.join("\n"))
        }
        // const area = document.getElementById(id)
        // area.value = lines.replaceAll(",", "\n")
    }

    /**
     * Function to set the value of a checkbox
     *
     * @param id ID of the element
     * @param state true/false for checked/unchecked
     */
    static updateCheckbox(id, state, container=$(document)) {
        const checkbox = container.find(`#${id}`)
        checkbox[0].checked = state
        updateCheckboxValue(checkbox, state)
    }

    /**
     * Updates the value of a date input field with the given date.
     *
     * @param {string} id - The ID of the date input field.
     * @param {Date} date - The date to set for the input field.
     * @param {jQuery} [container=$('document')] - The container where the input field is located.
     */
    static updateDateInput(id, date, container=$(document)) {
        try {
            container.find(`#${id}`).val(date.toISOString().substring(0, 10))
        } catch (e) {
            console.error("Error updating date input:", e)
        }
    }

    /**
     * Validates the input fields within the given container.
     *
     * @param {jQuery} container - The container where the input fields are located.
     * @returns {boolean} - True if all required input fields are valid; false otherwise.
     */
    static validateInputs(container) {
        const inputs = container.find("input, select")
        for (let input of inputs) {
            input = $(input)
            if (input.is(":hidden")) {
                continue
            }

            if (input.attr("required")) {
                if (!input[0].reportValidity()) {
                    return false
                }
            }
        }

        return true
    }
}

try {
    module.exports = FormUtils
} catch (ReferenceError) {

}

// export default FormUtils